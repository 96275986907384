$(function() {
  $("#paymentButton").click(function (event) {
    event.preventDefault();
    var gtc_accepted = $("#gtc_checkbox").prop('checked');

    if (gtc_accepted) {
      $("#alert-gtc-not-accepted").addClass("hidden");
    } else {
      $("#alert-gtc-not-accepted").removeClass("hidden");
    }

    if (gtc_accepted) {
      Datatrans.startPayment({'form': '#paymentForm'});
    }
  });

  $("#print_submit").click(function (event) {
    var gtc_accepted = $("#agb").prop('checked');
    if (gtc_accepted) {
      $("#alert-gtc-not-accepted").addClass("hidden");
    } else {
      $("#alert-gtc-not-accepted").removeClass("hidden");
      return false;
    }
  });

  $(".choose-plan").on("click", function(event) {
    event.preventDefault();
    $(event.currentTarget).find(".radio-for-plan-to-choose").prop("checked", true);
    $(event.currentTarget).closest("form").submit();
  });

  $(".choose-print-plan").on("click", function(event) {
    event.preventDefault();
    window.location = $(this).data('href');
  });

  $('.checkbox-option').on("click", function(e){
    var other_address_accepted = e.target.name === 'other' && e.target.checked
    if (e.target.name === 'other') {
      if (other_address_accepted) {
        $("#other-address").addClass("show-other-address");
        $(".new-address").prop('required',true);
      } else {
        $("#other-address").removeClass("show-other-address");
        $(".new-address").prop('required',false);
      }
    }
  });

  var voucher_timeout;
  $('.voucher-code').on('keyup', function(e){
    $('#alert-invalid-voucher').addClass('hidden');
    var voucher_code = $(this).val();
    clearTimeout(voucher_timeout);
    voucher_timeout = setTimeout(function(){
      $.ajax({
        method: "GET",
        url: "/vouchers/" + voucher_code,
        dataType: 'json'
      })
        .done(function(voucher) {
          var currency = $('#paymentForm').data('currency');
          var currentAmount = $('#paymentForm').data('amount') / 100;
          if (voucher.error){
            $('#alert-invalid-voucher').removeClass('hidden');
            $('.voucher-price').html('');
            $('.total-price').html(currency + ' ' + currentAmount.toFixed(2));
            $('#paymentForm').attr('data-amount', Math.round(currentAmount * 100));
            $('#paymentForm').removeAttr('data-voucher_code');
            $('#paymentForm').removeAttr('data-voucher_amount');
          } else {
            var voucherAmount = roundAmount(currentAmount * voucher.value/100).toFixed(2);
            var totalAmount = (currentAmount - voucherAmount).toFixed(2);
            $('.voucher-price').html(currency + ' ' + voucherAmount);
            $('.total-price').html(currency + ' ' + totalAmount);
            $('#paymentForm').attr('data-amount', totalAmount * 100);
            $('#paymentForm').attr('data-voucher_code', voucher.code);
            $('#paymentForm').attr('data-voucher_amount', voucherAmount * 100);
            $('#alert-invalid-voucher').addClass('hidden');
          }
        });
    }, 500);

  });
  if($('#checkbox_german').length > 0) {
    var german = document.getElementById('checkbox_german').getElementsByTagName('input')[0];
    var french = document.getElementById('checkbox_french').getElementsByTagName('input')[0];
    if(german){
      if(!german.checked || !french.checked ) {
        $('#checkbox_german input').prop("required", true)
      }
    }
    var female = document.getElementById('checkbox_female').getElementsByTagName('input')[0];
    var male = document.getElementById('checkbox_male').getElementsByTagName('input')[0];
    var family = document.getElementById('checkbox_family').getElementsByTagName('input')[0];
    if(female){
      if(!female.checked || !male.checked || !family.checked) {
        $('#checkbox_female input').prop("required", true)
      }
    }
  }
});

function roundAmount(value) {
  var converted = parseFloat(value); // Make sure we have a number
  var decimal = (converted - parseInt(converted, 10));
  var roundDecimal = Math.round(decimal * 100);
  var alpha = roundDecimal % 5;
  if (alpha == 0) { return (parseInt(converted, 10) + roundDecimal/100); }
  if (alpha >= 3) { return (parseInt(converted, 10) + (roundDecimal + 5 - alpha)/100); }
  if (alpha < 3) { return (parseInt(converted, 10) + (roundDecimal - alpha)/100); }
}
