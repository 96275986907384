$(document).ready(function() {
  if ($('#login-success-with-iframe').html() != undefined) {
    window.top.postMessage("logged", '*');
  }

  if ($('#sign_in_out')) {
    if (window != window.top) {
      $( "#sign_in_out" ).addClass('in-iframe');
      $('#footer').hide();
    }
  }

  if( $('#sign_in_out .alert').length > 0 ) {
    $("#user_email, #user_password").click(function() { 
      $('#sign_in_out').scrollTop($(document).height()); 
     });
  };
});
