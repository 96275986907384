$(document).ready(function() {
  $('.unlock-customer-number').on('input', function(){
    let login = $(this).val();
    let $customerInput = $(this);
    if (login.length == 8) {
      $.ajax({
        method: "GET",
        url: '/new_login',
        data: { login: login },
        dataType: 'json'
      }).done(function(res) {
        if (res['login']) {
          $customerInput.val(res['login']);
        }
      });
    }
  });
});
